/* storybook-check-ignore */
import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import SessionApi from '@opendoor/shared-fe/build/api/session';
import { sagaMiddlewareErrorHandler } from '@opendoor/shared-fe/build/sagas';
import session from '@opendoor/shared-fe/build/store/session';
import SessionSaga from '@opendoor/shared-fe/build/store/session/saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { RAILS_URL, XSRF_COOKIE_KEY } from '../globals';

const sagas = function* sagas() {
  yield all([fork(new SessionSaga(new SessionApi(XSRF_COOKIE_KEY, RAILS_URL)).saga)]);
};

const sagaMiddleware = createSagaMiddleware({ onError: sagaMiddlewareErrorHandler });
const composeEnhancers =
  (typeof window !== 'undefined' && (window as any)).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
  compose;
const reducers = { session: session.reducer };
const store = createStore(
  combineReducers({ ...reducers }),
  undefined,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);
sagaMiddleware.run(sagas);

const ReduxWrapper: React.FC<PropsWithChildren> = (props) => {
  return <Provider store={store}>{props.children}</Provider>;
};

export default ReduxWrapper;
