/* storybook-check-ignore */
import { useEffect, useRef } from 'react';

import { Heading, Loader } from '@opendoor/bricks/core';

import { useMediaQueryContext } from 'components/exclusives/contexts/MediaQueryContext';
import { DesktopHeaderSearchResults } from 'components/exclusives/layout/Headerv2/HeaderSearchResults/DesktopHeaderSearchResults';
import { HeaderSearchResultsItem } from 'components/exclusives/layout/Headerv2/HeaderSearchResults/HeaderSearchResultsItem';
import { MobileHeaderSearchResults } from 'components/exclusives/layout/Headerv2/HeaderSearchResults/MobileHeaderSearchResults';

import { Listing } from 'declarations/exclusives/listing';
import { MarketLocation } from 'declarations/exclusives/market';

export type ListingSearchResult = Pick<Listing, 'id' | 'slug'> & {
  readable_address: Listing['address']['human_readable'];
};

export type LocationSearchResult = {
  identifier: MarketLocation;
  title: string;
};

export type HeaderSearchResultsProps = {
  isLoading?: boolean;
  listings: ListingSearchResult[];
  locations: LocationSearchResult[];
  selectedIndex?: number;
  onSelect?: () => void;
  /**
   * The regular expressions used to highlight search results.
   */
  searchQueryRegExps?: RegExp[];
};

export const HeaderSearchResults = ({
  isLoading,
  listings,
  locations,
  onSelect,
  selectedIndex,
  searchQueryRegExps,
}: HeaderSearchResultsProps) => {
  /**
   * We use `useMedia` over `useDevice` because the `useDevice` MD breakpoint
   * returns the value `768` whereas the MD value in the Emotion ThemeProivder
   * is `992` which means that we'll have an inconsistent behavior when
   * using both.
   *
   * FIXME: Sync with Platform team to get this resolved.
   */
  const { isMobile } = useMediaQueryContext();
  const HeaderSearchResultsContainer = isMobile
    ? MobileHeaderSearchResults
    : DesktopHeaderSearchResults;
  const navRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedIndex === undefined) return;

    const locationId = locations?.[selectedIndex]?.identifier || listings?.[selectedIndex]?.id;

    const element = locationId ? document.getElementById(`search-result-${locationId}`) : null;

    if (navRef.current && element) {
      navRef.current.scrollTop = element?.offsetTop;
      element.focus();
    }
  }, [navRef, selectedIndex]);

  return (
    <HeaderSearchResultsContainer ref={navRef}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!!listings?.length && (
            <>
              <Heading
                as="h3"
                color="neutrals100"
                css={{ lineHeight: '40px' }}
                fontSize="s1"
                fontWeight="medium"
                m={0}
                px={5}
                py={0}
              >
                Homes
              </Heading>
              {listings?.map((listing) => {
                return (
                  <HeaderSearchResultsItem
                    href={`/exclusives/homes/${encodeURIComponent(listing.slug)}`}
                    key={listing.id}
                    id={`search-result-${listing.id}`}
                    label={listing.readable_address}
                    onClick={onSelect}
                    searchQueryRegExps={searchQueryRegExps}
                  />
                );
              })}
            </>
          )}

          {!!locations?.length && (
            <>
              <Heading
                as="h3"
                backgroundColor="neutrals0"
                color="neutrals100"
                css={{ lineHeight: '40px' }}
                fontSize="s1"
                fontWeight="medium"
                m={0}
                px={5}
                py={0}
                position="sticky"
                top={-5}
                zIndex={1}
              >
                Locations
              </Heading>

              {locations.map((location) => (
                <HeaderSearchResultsItem
                  href={`/exclusives/gallery/${location.identifier}`}
                  key={location.identifier}
                  id={`search-result-${location.identifier}`}
                  label={location.title}
                  onClick={onSelect}
                  searchQueryRegExps={searchQueryRegExps}
                />
              ))}
            </>
          )}
        </>
      )}
    </HeaderSearchResultsContainer>
  );
};
