/** storybook-check-ignore */
import React, { PropsWithChildren } from 'react';

import { AddContextualData, globalObservability } from '@opendoor/observability/slim';
import merge from 'lodash/merge';

const datadogRum = globalObservability.getDatadogRumClient();

export const updateRUMGlobalContext = (context: { [k: string]: unknown }) => {
  datadogRum.setRumGlobalContext?.({ ...(datadogRum.getRumGlobalContext?.() || {}), ...context });
};

export type ExclusivesTypeContextProps = {
  type: 'exclusives' | 'exclusive-offers';
  updateContextualOptions: (newOptions: Record<string, unknown>) => void;
};

const ExclusivesTypeContext = React.createContext<ExclusivesTypeContextProps>({
  type: 'exclusives',
  updateContextualOptions: () => {},
});

export const useExclusivesTypeContext = () => React.useContext(ExclusivesTypeContext);

export const ExclusivesTypeContextProvider: React.FC<
  PropsWithChildren<{
    type: ExclusivesTypeContextProps['type'];
  }>
> = ({ type, children }) => {
  const [options, setOptions] = React.useState<Record<string, unknown>>({});

  /**
   * Merges the existing contextual data options with a new set of options.
   * The newly provided options will take precedence over the existing options.
   */
  const updateContextualOptions = React.useCallback(
    (newOptions: Record<string, unknown>) => {
      Object.keys(newOptions).forEach((key) => {
        if (Object.keys(options).includes(key)) {
          delete newOptions[key];
        }
      });

      if (Object.keys(newOptions).length > 0) {
        setOptions(merge(options, newOptions));
        updateRUMGlobalContext(newOptions);
      }
    },
    [options],
  );

  return (
    <ExclusivesTypeContext.Provider value={{ type, updateContextualOptions }}>
      <AddContextualData data={{ exclusivesType: type }}>{children}</AddContextualData>
    </ExclusivesTypeContext.Provider>
  );
};
