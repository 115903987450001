export const ExclusivesRoutes = {
  BUYER_ONBOARDING: '/exclusives/buyer-onboarding',
  BUYER_ONBOARDING_CONFIRM: '/exclusives/buyer-onboarding/confirm',
  FAQ: '/exclusives/faq',
  GALLERY: '/exclusives/gallery',
  HOMES: '/exclusives/homes',
  LOGIN: '/exclusives/login',
  OFFERS: '/exclusives/offers',
  PREFERENCES: '/exclusives/preferences',
  ROOT: '/exclusives',
};

export const OpendoorRoutes = {
  ABOUT: 'https://opendoor.com/w/about',
  AGENTS: 'https://opendoor.com/w/agents',
  BLOG: 'https://opendoor.com/w/blog',
  CAREERS: 'https://opendoor.com/jobs',
  CCPA: 'https://www.opendoor.com/privacy#ccpa',
  CONTACT: 'https://opendoor.com/w/contact',
  GUIDES: 'https://opendoor.com/w/resources',
  MAKE_OFFER: 'https://opendoor.com/make-offer/start',
  PRESS: 'https://opendoor.com/press',
  PRIVACY_POLICY: 'https://opendoor.com/privacy',
  PRIVACY_REQUEST: 'https://opendoor.com/privacyrequest',
  TERMS_OF_SERVICE: 'https://opendoor.com/terms',
};

export const ExternalRoutes = {
  BROKERAGE_SERVICES_INFO:
    'https://drive.google.com/file/d/1x0sT_FhzsML30lDv6HpFGr9f_izW4jcJ/view?usp=sharing',
  CONSUMER_PROTECTION_NOTICE: 'https://www.trec.texas.gov/forms/consumer-protection-notice',
  EMAIL: 'mailto:exclusive@opendoor.com',
  HELP_CENTER: 'https://help.opendoor.com/',
  CXA_PHONE: 'tel:1-888-352-7075',
  CCA_PHONE: 'tel:1-512-706-9938',
  INVESTOR: 'https://investor.opendoor.com/',
};
