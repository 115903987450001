/* storybook-check-ignore */
import type { ReactNode } from 'react';

import { ListItem } from '@opendoor/bricks/core';

import {
  HeaderNavigationItem,
  HeaderNavigationItemProps,
} from 'components/exclusives/layout/Headerv2/HeaderNavigation/HeaderNavigationItem';

export type HeaderMenuItemProps = Pick<
  HeaderNavigationItemProps,
  'analyticsName' | 'as' | 'children' | 'href' | 'onClick'
> & {
  icon?: ReactNode;
  isMobileOnly?: boolean;
  label: string;
};

export const HeaderMenuItem = ({
  analyticsName,
  as,
  href,
  isMobileOnly,
  icon,
  label,
  onClick,
}: HeaderMenuItemProps) => {
  return (
    <ListItem display={isMobileOnly ? ['block', null, null, 'none'] : 'block'}>
      <HeaderNavigationItem
        analyticsName={analyticsName}
        aria-label={`Go to ${label}`}
        as={as}
        href={href}
        onClick={onClick}
      >
        {icon}
        {label}
      </HeaderNavigationItem>
    </ListItem>
  );
};
