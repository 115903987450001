/* storybook-check-ignore */
import React, { PropsWithChildren } from 'react';

import * as aos from 'aos';
import * as NextImport from 'next/head';
import { css } from '@emotion/react';
import faviconPath from '@opendoor/shared-fe/build/faviconPath';

import { DEPLOY_ENV } from './globals';

const NextHead = NextImport.default;

const metaDescription =
  'Opendoor is the new way to sell your home. ' +
  'Skip the hassle of listing, showings and months of stress, and ' +
  'close on your own timeline. Get a free offer today!';

export const Head = () => {
  return (
    <NextHead>
      <title>Opendoor | Sell your home the minute you're ready.</title>
      <meta key="description" name="description" content={metaDescription} />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <link rel="shortcut icon" href={faviconPath({ railsEnv: DEPLOY_ENV })} type="image/png" />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="https://www.opendoor.com/apple-touch-icon-144.png"
      />

      <link
        rel="preload"
        crossOrigin="anonymous"
        href="/fonts/graphik-regular/Graphik-Regular.woff"
        as="font"
      />
      <link
        rel="preload"
        crossOrigin="anonymous"
        href="/fonts/graphik-medium/Graphik-Medium.woff"
        as="font"
      />
      <link
        rel="preload"
        crossOrigin="anonymous"
        href="/fonts/graphik-semibold/Graphik-Semibold.woff"
        as="font"
      />
      <link
        rel="preload"
        crossOrigin="anonymous"
        href="/fonts/graphik-bold/Graphik-Bold.woff"
        as="font"
      />
    </NextHead>
  );
};

export class Page extends React.Component<PropsWithChildren> {
  componentDidMount() {
    window.requestAnimationFrame(() => {
      aos.init();
    });
  }

  render() {
    return (
      <div
        css={css`
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          -webkit-flex: 1;
          flex: 1;
          margin: 0 auto;
          background-color: #fff;
          position: relative;
        `}
      >
        {this.props.children}
      </div>
    );
  }
}
