/* storybook-check-ignore */
import { ReactNode } from 'react';

import { Flex, Icon } from '@opendoor/bricks/core';
import { useRouter } from 'next/router';

import { HeaderAvatar } from 'components/exclusives/layout/Headerv2/HeaderAvatar/HeaderAvatar';
import HeaderMenu from 'components/exclusives/layout/Headerv2/HeaderMenu/HeaderMenu';
import { HeaderNavigationItem } from 'components/exclusives/layout/Headerv2/HeaderNavigation/HeaderNavigationItem';
import LoggedInConnector, { AccountProps } from 'components/shared/AccountConnector';

import { useExclusivesTypeContext } from 'helpers/exclusives/ExclusivesTypeContext';

export type HeaderNavigationProps = AccountProps & {
  isLoggedIn?: boolean;
  menu?: ReactNode;
};

export const HeaderNavigation = ({
  human,
  loggedIn: isLoggedIn,
  menu = <HeaderMenu />,
}: HeaderNavigationProps) => {
  const router = useRouter();
  const { type } = useExclusivesTypeContext();

  const name: string | undefined = human?.get('full_name');
  const initials = name ? name.split(' ').map((n) => n[0]) : [];
  const isExclusives = type === 'exclusives';
  const telephone = isExclusives ? '1-512-706-9938' : '1-888-352-7075';
  const analyticsPrefix = `cosmos-${type}-header`;

  return (
    <Flex as="nav" alignItems="center" flexShrink={0} gap={3}>
      {isLoggedIn ? (
        <>
          <HeaderNavigationItem
            analyticsName={`${analyticsPrefix}-preferences`}
            aria-label="Go to Preferences"
            display={['none', null, null, 'flex']}
            href="/exclusives/preferences"
          >
            <Icon
              color="currentColor"
              name="filter"
              // @ts-expect-error - This is a known deviation from the design system.
              size={20}
            />
            Preferences
          </HeaderNavigationItem>
          <HeaderNavigationItem
            analyticsName={`${analyticsPrefix}-saved-homes`}
            aria-label="Go to Saved homes"
            display={['none', null, null, 'flex']}
            href="/exclusives/saved-homes"
          >
            <Icon
              color="currentColor"
              name="favorite"
              // @ts-expect-error - This is a known deviation from the design system.
              size={20}
            />
            Your homes
          </HeaderNavigationItem>

          <HeaderNavigationItem
            analyticsName={`cosmos-${type}-header-${isExclusives ? 'sell' : 'buy'}`}
            aria-label={`Go to ${isExclusives ? 'sell' : 'buy'} with Exclusives`}
            display={['none', null, null, 'flex']}
            href={isExclusives ? '/exclusives/offers' : '/exclusives'}
          >
            {isExclusives ? 'Sell' : 'Buy'} with Exclusives
          </HeaderNavigationItem>
        </>
      ) : (
        <>
          <HeaderNavigationItem
            analyticsName={`${analyticsPrefix}-contact-phone`}
            aria-label={`Call us ${telephone}`}
            display={['none', null, null, 'flex']}
            href={`tel:${telephone}`}
          >
            Call: {telephone}
          </HeaderNavigationItem>

          <HeaderNavigationItem
            analyticsName={`cosmos-${type}-header-${isExclusives ? 'sell' : 'buy'}`}
            aria-label={`Go to ${isExclusives ? 'sell' : 'buy'} with Exclusives`}
            display={['none', null, null, 'flex']}
            href={isExclusives ? '/exclusives/offers' : '/exclusives'}
          >
            {isExclusives ? 'Sell' : 'Buy'} with Exclusives
          </HeaderNavigationItem>

          <HeaderNavigationItem
            analyticsName={`${analyticsPrefix}-FAQs`}
            aria-label="Go to FAQs"
            display={['none', null, null, 'flex']}
            href="/exclusives/faq"
          >
            FAQs
          </HeaderNavigationItem>

          <HeaderNavigationItem
            analyticsName={`${analyticsPrefix}-sign-in`}
            aria-label="Go to sign in"
            href={`/exclusives/login/?redirectTo=${encodeURIComponent(router.asPath)}`}
          >
            Sign in
          </HeaderNavigationItem>
        </>
      )}

      {isLoggedIn && initials && <HeaderAvatar initials={initials} />}

      {menu}
    </Flex>
  );
};

export default LoggedInConnector(HeaderNavigation);
