/* storybook-check-ignore */
import { Flex, Image, Link, LinkProps } from '@opendoor/bricks/core';

import { ExclusivesRoutes } from 'components/exclusives/constants/routes';

import { useExclusivesTypeContext } from 'helpers/exclusives/ExclusivesTypeContext';

export type HeaderLogoProps = Pick<LinkProps, 'href'>;

export const HeaderLogo = ({ href = ExclusivesRoutes.GALLERY }: HeaderLogoProps) => {
  const { type } = useExclusivesTypeContext();

  return (
    <Link
      analyticsName={`cosmos-${type}-header-logo`}
      aria-label="Go to home"
      flexShrink={0}
      href={href}
    >
      <Flex alignItems="center" display={['none', null, null, 'flex']} height={40} pr={3}>
        <Image
          alt="Opendoor Exclusives logo"
          height={32}
          src="https://images.opendoor.com/source/s3/imgdrop-production/df3f4c2922ba4c4dbcc2f8142f9b3dbe.svg?preset=square-2048"
        />
      </Flex>

      <Flex
        alignItems="center"
        display={['flex', null, null, 'none']}
        height={40}
        justifyContent="center"
        width={40}
      >
        <Image
          alt="Opendoor Exclusives logo"
          height={32}
          src="https://images.opendoor.com/source/s3/imgdrop-production/0bee6386b0ca470b8a00382ed6adbfa3.svg?preset=square-2048"
          width={32}
        />
      </Flex>
    </Link>
  );
};
