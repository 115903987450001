/* storybook-check-ignore */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLockBodyScroll } from 'react-use';

import { Box, Button, Icon, InputBase } from '@opendoor/bricks/core';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import debounce from 'lodash/debounce';
import useSWR from 'swr';

import { doGet } from 'components/api';
import { useMediaQueryContext } from 'components/exclusives/contexts/MediaQueryContext';
import { HeaderSearchOverlay } from 'components/exclusives/layout/Headerv2/HeaderSearch/HeaderSearchOverlay';
import {
  HeaderSearchResults,
  ListingSearchResult,
  LocationSearchResult,
} from 'components/exclusives/layout/Headerv2/HeaderSearchResults/HeaderSearchResults';

import { MARKET_TEXT, MARKETS, STATE } from 'declarations/exclusives/market';

import { useExclusivesTypeContext } from 'helpers/exclusives/ExclusivesTypeContext';

export const HeaderSearch = () => {
  const shouldReduceMotion = useReducedMotion();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const { isMobile } = useMediaQueryContext();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSetSearchQuery = useCallback(debounce(setSearchQuery, 100), []);
  const { type } = useExclusivesTypeContext();
  const { data: listingSearchResults, isLoading: isListingSearchResultsLoading } = useSWR(
    searchQuery ? ['/exclusives/search', searchQuery] : null,
    searchFetcher,
  );
  const analyticsPrefix = `cosmos-${type}-header-menu`;

  /**
   * We disable the body scroll because we want to prevent
   * the user from scrolling the page when the search overlay
   * is open because it should be treated like a modal.
   */
  useLockBodyScroll(isOpen);

  const locationSearchResult = useMemo<LocationSearchResult[]>(() => {
    const regex = new RegExp(`(${searchQuery.trim()})`, 'gi');

    return MARKETS.map((identifier) => ({
      identifier,
      title: `${MARKET_TEXT[identifier]}, ${STATE[identifier]}`,
    })).filter(({ title }) => regex.test(title));
  }, [searchQuery]);

  /**
   * The regular expressions used to highlight search results.
   */
  const searchQueryRegExps = useMemo(
    () =>
      searchQuery
        .trim()
        .split(' ')
        .filter((w) => !!w.trim())
        .map((w) => new RegExp(`(${w})`, 'gi')),
    [searchQuery],
  );

  /**
   * The total number of search results.
   */
  const searchResultsLength = useMemo(
    () => (listingSearchResults?.length ?? 0) + locationSearchResult.length,
    [listingSearchResults, locationSearchResult],
  );

  /**
   * Increment the selected index used to highlight a search result item.
   */
  const onIncrementSelectedIndex = useCallback(() => {
    setSelectedIndex((previousSelectedIndex) =>
      previousSelectedIndex === undefined || previousSelectedIndex === searchResultsLength - 1
        ? 0
        : previousSelectedIndex + 1,
    );
  }, [searchResultsLength]);

  /**
   * Decrement the selected index used to highlight a search result item.
   */
  const onDecrementSelectedIndex = useCallback(() => {
    setSelectedIndex((previousSelectedIndex) =>
      previousSelectedIndex === undefined || previousSelectedIndex === 0
        ? searchResultsLength - 1
        : previousSelectedIndex - 1,
    );
  }, [searchResultsLength]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    searchInputRef?.current?.blur();
  }, []);

  const onKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Tab' && event.shiftKey) {
        event.preventDefault();
        onDecrementSelectedIndex();
      } else if (event.key === 'Tab') {
        event.preventDefault();
        onIncrementSelectedIndex();
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        onIncrementSelectedIndex();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        onDecrementSelectedIndex();
      } else if (event.key === 'Escape') {
        onClose();
      } else {
        /**
         * Resets the selected index when the user starts typing again.
         */
        setSelectedIndex(undefined);
        searchInputRef?.current?.focus();
      }
    },
    [onClose, onIncrementSelectedIndex, onDecrementSelectedIndex],
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [isOpen, onKeyPress]);

  return (
    <Box width="100%">
      <Box position="relative" zIndex={2}>
        <MotionBox
          animate={{
            width: isOpen && isMobile ? 'calc(100vw - 32px)' : 'auto',
            x: isOpen && isMobile ? -56 : 0,
          }}
          transition={{
            duration: shouldReduceMotion ? 0 : 0.2,
          }}
          position="relative"
        >
          <InputBase
            // @ts-expect-error - This is a known deviation from the design system.
            borderRadius={12}
            css={{ lineHeight: '40px' }}
            height={40}
            onChange={(event) => debouncedSetSearchQuery(event.target.value)}
            onFocus={() => setIsOpen(true)}
            pl={40}
            placeholder="Search address, city, or zip"
            ref={searchInputRef}
            width="100%"
          />
          {isOpen && isMobile ? (
            <Button
              _active={{ backgroundColor: 'transparent' }}
              _hover={{ backgroundColor: 'transparent' }}
              analyticsName={analyticsPrefix}
              aria-label="Close search"
              backgroundColor="transparent"
              border="none"
              boxShadow="none"
              borderRadius={0}
              // @ts-expect-error - This is a known deviation from the design system.
              borderLeftRadius={10}
              onClick={onClose}
              position="absolute"
              top={0}
              variant="icon"
            >
              <Icon
                name="arrow-left"
                // @ts-expect-error - This is a known deviation from the design system.
                size={20}
              />
            </Button>
          ) : (
            <Icon
              color="neutrals100"
              left={4}
              name="search"
              position="absolute"
              // @ts-expect-error - This is a known deviation from the design system.
              size={20}
              top="calc(50% - 10px)"
            />
          )}
        </MotionBox>
        <AnimatePresence>
          {isOpen && (
            <HeaderSearchResults
              isLoading={isListingSearchResultsLoading}
              locations={locationSearchResult}
              listings={listingSearchResults ?? []}
              onSelect={onClose}
              selectedIndex={selectedIndex}
              searchQueryRegExps={searchQueryRegExps}
            />
          )}
        </AnimatePresence>
      </Box>

      <AnimatePresence>{isOpen && <HeaderSearchOverlay onClick={onClose} />}</AnimatePresence>
    </Box>
  );
};

const MotionBox = motion(Box);

async function searchFetcher([_, query]: [_: unknown, query: string]) {
  const queryParams = new URLSearchParams({
    query,
    limit: '100',
    exclusive_searchables_only: 'true',
  });

  const response = await doGet(`/api/v1/listings/search_listings?${queryParams}`, { retries: 0 });
  const body: ListingSearchResult[] = (await response.json()) || [];

  return body;
}
