/* storybook-check-ignore */
import { Link, LinkProps } from '@opendoor/bricks/core';

export type HeaderNavigationItemProps = LinkProps & Required<Pick<LinkProps, 'href'>>;

export const HeaderNavigationItem = ({ href, ...restOfProps }: HeaderNavigationItemProps) => {
  return (
    <Link
      _active={{
        backgroundColor: 'neutrals40',
      }}
      _hover={{
        backgroundColor: 'neutrals20',
      }}
      alignItems="center"
      border={0}
      // @ts-expect-error - This is a known deviation from the design system.
      borderRadius={12}
      color="neutrals100"
      display="flex"
      fontSize="s1"
      fontWeight="medium"
      gap={2}
      height={40}
      href={href}
      lineHeight="s1"
      px={4}
      textDecoration="none"
      variant="inline-secondary"
      {...restOfProps}
    />
  );
};
