/* storybook-check-ignore */
import { useEffect } from 'react';

import { Box, Flex } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ArrowLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowLeft';
import AvatarFilled from '@opendoor/bricks/core/Icon/SpritesheetIcons/AvatarFilled';
import Close from '@opendoor/bricks/core/Icon/SpritesheetIcons/Close';
import Favorite from '@opendoor/bricks/core/Icon/SpritesheetIcons/Favorite';
import Filter from '@opendoor/bricks/core/Icon/SpritesheetIcons/Filter';
import Menu from '@opendoor/bricks/core/Icon/SpritesheetIcons/Menu';
import Search from '@opendoor/bricks/core/Icon/SpritesheetIcons/Search';

import { HeaderLogo } from 'components/exclusives/layout/Headerv2/HeaderLogo/HeaderLogo';
import HeaderNavigation from 'components/exclusives/layout/Headerv2/HeaderNavigation/HeaderNavigation';
import { HeaderSearch } from 'components/exclusives/layout/Headerv2/HeaderSearch/HeaderSearch';
import LoggedInConnector, { AccountProps } from 'components/shared/AccountConnector';
import ImpersonationHeader from 'components/shared/ImpersonationBar';

export const HEADER_HEIGHT = 72;

export type HeaderV2Props = {
  hasBorderBottom?: boolean;
  isFullWidth?: boolean;
  isNavigationHidden?: boolean;
  isSearchHidden?: boolean;
  isSticky?: boolean;
  isTransparentBg?: boolean;
  maxWidth?: string;
  logoHref?: string;
};

export const HeaderV2 = ({
  hasBorderBottom = true,
  isFullWidth = false,
  isNavigationHidden = false,
  isSearchHidden = false,
  isSticky = true,
  isTransparentBg = false,
  maxWidth = 'var(--content-full-max-width)',
  maybeFetchSessionInfo,
  human,
  logoHref,
}: HeaderV2Props & AccountProps) => {
  useEffect(() => {
    maybeFetchSessionInfo();
  }, []);

  const isImpersonatedHuman = human && human.get('impersonated');

  return (
    <>
      <SelectiveSpritesheet
        icons={[ArrowLeft, AvatarFilled, Close, Favorite, Filter, Menu, Search]}
      />
      <Flex
        alignItems="center"
        as="header"
        backgroundColor={isTransparentBg ? 'transparent' : 'neutrals0'}
        borderBottom={hasBorderBottom ? '1px solid' : 'none'}
        borderColor="neutrals40"
        flexShrink={0}
        left={0}
        height={HEADER_HEIGHT}
        maxWidth="100%"
        position={isSticky ? 'sticky' : 'relative'}
        top={0}
        right={0}
        zIndex={9}
      >
        <Flex
          alignItems="center"
          gap={5}
          justifyContent="space-between"
          maxWidth={isFullWidth ? '100%' : maxWidth}
          mx="auto"
          pl={[24, null, 40]}
          pr={[24, null, 28]}
          position="relative"
          width="100%"
          /**
           * This is to prevent the search bar from overflowing the header on
           * mobile devices.
           */
          overflow={['hidden', null, null, 'visible']}
        >
          <Flex alignItems="center" flexGrow={1} gap={[3, null, null, 5]}>
            <HeaderLogo href={logoHref} />

            {!isSearchHidden && <HeaderSearch />}
          </Flex>

          {isImpersonatedHuman && (
            <Box lineHeight="100" height="100%" padding={'10px'} position="relative">
              <ImpersonationHeader email={human.get('email')} name={human.get('full_name')} />
            </Box>
          )}

          {!isNavigationHidden && <HeaderNavigation />}
        </Flex>
      </Flex>
    </>
  );
};

export default LoggedInConnector(HeaderV2);
