/* storybook-check-ignore */
import { Flex, Icon, Text } from '@opendoor/bricks/core';

export type HeaderAvatarProps = {
  initials?: string[];
};

/**
 * We're creating our own `Avatar` here since the version in the design system
 * is to limited for our needs in terms of visually styling.
 */
export const HeaderAvatar = ({ initials }: HeaderAvatarProps) => {
  return (
    <Flex alignItems="center" justifyContent="center" height={40} width={40}>
      <Flex
        alignItems="center"
        backgroundColor="neutrals100"
        borderRadius="rounded"
        height={32}
        justifyContent="center"
        border="2px solid"
        borderColor="neutrals100"
        overflow="hidden"
        width={32}
      >
        {initials ? (
          <Text color="neutrals0" fontSize="s00" fontWeight="semibold" lineHeight="s00">
            {initials}
          </Text>
        ) : (
          <Icon color="neutrals0" mt={4} name="avatar-filled" size={32} />
        )}
      </Flex>
    </Flex>
  );
};
