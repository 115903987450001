/* storybook-check-ignore */
import { ReactNode } from 'react';

/* eslint-disable @opendoor/no-html-elements */
import { css, Global } from '@emotion/react';
import { Subset } from '@opendoor/bricks/declarations/Subset';
import { ITheme } from '@opendoor/bricks/system/theme';
import { ThemeName } from '@opendoor/bricks/theme';
import { media } from '@opendoor/bricks/theme/legacy/foundations/mediaBreakpoints';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { MediaQueryContextProvider } from 'components/exclusives/contexts/MediaQueryContext';
import { useSetDocHeight } from 'components/exclusives/hooks/useSetDocHeight';
import { FrontChatProps } from 'components/exclusives/layout/FrontChat';
import { HotjarScript } from 'components/exclusives/scripts/HotjarScript';
import { MetaPixelScript } from 'components/exclusives/scripts/MetaPixelScript';
import GlobalStyles from 'components/shared/GlobalStyles';
import MaintenanceBanner from 'components/shared/MaintenanceBanner';
import ReduxWrapper from 'components/shared/ReduxWrapper';

import * as WordpressHeadGlobal from '../../WordpressPorts';

const FrontChat = dynamic(() => import('components/exclusives/layout/FrontChat'), {
  ssr: false,
});

export type BaseLayoutProps = FrontChatProps & {
  children?: ReactNode;

  /**
   * The description of the page.
   */
  description?: string;

  /**
   * Determines whether or not to include the Hotjar script.
   * @see https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code
   * @default false
   */
  hasHotjarScript?: boolean;

  /**
   * Determines whether or not to include the Meta Pixel script.
   * @see https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking/
   * @default true
   */
  hasMetaPixelScript?: boolean;

  /**
   * The theme that is applied to the design system.
   * @default `exclusives`
   */
  theme?: ThemeName;
  themeOverrides?: Subset<ITheme>;

  /**
   * The title of the page.
   * @default `Opendoor`
   */
  title?: string;
};

export const BaseLayout = ({
  children,
  description,
  hasHotjarScript,
  hasMetaPixelScript = true,
  hideBubble,
  hideFrontChat,
  theme = 'exclusives',
  themeOverrides,
  title = 'Opendoor',
}: BaseLayoutProps) => {
  useSetDocHeight();

  return (
    <ReduxWrapper>
      <>
        <Head>
          <title>{title}</title>
          <meta key="og:title" property="og:title" content={title} />
          <meta key="twitter:title" name="twitter:title" content={title} />

          {description && (
            <>
              <meta key="description" name="description" content={description} />
              <meta key="og:description" property="og:description" content={description} />
              <meta key="twitter:description" name="twitter:description" content={description} />
            </>
          )}

          <meta
            key="og:image"
            property="og:image"
            content="https://images.opendoor.com/source/s3/imgdrop-production/0de83246f5284584b22165a7cb562cb7.png?preset=square-2048"
          />
          <meta property="og:image:width" content="4534" />
          <meta property="og:image:height" content="2551" />

          <link
            rel="preload"
            crossOrigin="anonymous"
            href="/fonts/PublicoHeadline-Bold-Web.woff2"
            as="font"
          />

          <noscript>
            <img
              alt=""
              height="1"
              width="1"
              style={{ display: 'none' }}
              src="https://www.facebook.com/tr?id=1610195155916409&ev=PageView&noscript=1"
            />
          </noscript>
        </Head>
        <MaintenanceBanner />
        <WordpressHeadGlobal.Page>
          <ThemedLite
            {...(themeOverrides
              ? {
                  baseTheme: theme,
                  launchDateString: new Date().toISOString(),
                  team: 'exclusives',
                  overrides: themeOverrides,
                }
              : { theme })}
          >
            <GlobalStyles />
            <Global styles={globalStyles} />
            <MediaQueryContextProvider>{children}</MediaQueryContextProvider>
          </ThemedLite>
        </WordpressHeadGlobal.Page>
        <FrontChat hideFrontChat={hideFrontChat} hideBubble={hideBubble} />

        {hasHotjarScript && <HotjarScript />}
        {hasMetaPixelScript && <MetaPixelScript />}
      </>
    </ReduxWrapper>
  );
};

const ASSET_PREFIX = 'https://cdn.opendoor.com/fonts';

const globalStyles = css`
  @font-face {
    font-family: 'Publico, serif';
    src: url('${ASSET_PREFIX}/PublicoHeadline-Bold-Web.eot?#iefix') format('embedded-opentype'),
      url('${ASSET_PREFIX}/PublicoHeadline-Bold-Web.woff') format('woff'),
      url('${ASSET_PREFIX}/PublicoHeadline-Bold-Web.ttf') format('truetype'),
      url('${ASSET_PREFIX}/PublicoHeadline-Bold-Web.svg#PublicoHeadline-Bold-Web') format('svg');
    font-weight: 600;
    font-style: bolder;
  }

  body {
    background-color: neutrals100;
  }

  input[type='checkbox'] {
    accent-color: var(--colors-brand50);
  }
  :root {
    --doc-height: 100vh;
    --header-max-width: 82rem;
    --content-max-width: 82rem;
    --content-narrow-max-width: 82rem;
    --content-full-max-width: 100rem;
    /**
     * Theme overrides doesn't work so we need to define the variables here 
     * that doesn't conflict with Novo.
     */
    --colors-exclusives-brand50: #082de6;
    --colors-exclusives-brand40: #6985ff;
    --colors-exclusives-brand30: #95a5fc;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    height: 100vh; /* fallback for Js load */
    height: var(--doc-height);
  }

  ${media.largerThan.LG} {
    :root {
      --header-max-width: max(82rem, 82vw);
      --content-max-width: max(82rem, 82vw);
      --content-narrow-max-width: max(82rem, 96vw);
    }
  }

  ${media.largerThan.XL} {
    :root {
      --header-max-width: max(82rem, 82vw);
      --content-max-width: max(82rem, 82vw);
      --content-narrow-max-width: max(82rem, 96vw);
    }
  }

  ${media.largerThan.XXL} {
    :root {
      --content-narrow-max-width: max(82rem, 96vw);
    }
  }
`;
