/* storybook-check-ignore */
import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { Flex } from '@opendoor/bricks/core';
import { motion } from 'framer-motion';

export type DesktopHeaderSearchResultsProps = ComponentPropsWithoutRef<typeof MotionFlex>;

export const DesktopHeaderSearchResults = forwardRef<
  HTMLDivElement,
  DesktopHeaderSearchResultsProps
>((props, forwardedRef) => {
  return (
    <MotionFlex
      animate={{ opacity: 1 }}
      as="nav"
      exit={{ opacity: 0 }}
      flexDirection="column"
      initial={{ opacity: 0 }}
      left={-5}
      maxHeight="calc(var(--doc-height) - 56px)"
      position="absolute"
      px={4}
      py={5}
      overflowY="auto"
      ref={forwardedRef}
      top="100%"
      transition={{ duration: 0.2 }}
      right={-5}
      zIndex={1}
      {...props}
    />
  );
});

DesktopHeaderSearchResults.displayName = 'DesktopHeaderSearchResults';

const MotionFlex = motion(Flex);
