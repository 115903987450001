/* storybook-check-ignore */
import { Button, ButtonProps, Icon, Text } from '@opendoor/bricks/core';

export type HeaderSearchResultsItemProps = Pick<ButtonProps, 'onClick'> & {
  href: string;
  id: string;
  label: string;
  onSelect?: () => void;
  /**
   * The regular expressions used to highlight search results.
   */
  searchQueryRegExps?: RegExp[];
};

export const HeaderSearchResultsItem = ({
  href,
  id,
  label,
  onClick,
  searchQueryRegExps,
}: HeaderSearchResultsItemProps) => {
  const words = label?.split(SPACE_REGEX);

  return (
    <Button
      _active={{
        backgroundColor: 'neutrals40',
      }}
      _hover={{
        backgroundColor: 'neutrals20',
      }}
      _focus={{
        backgroundColor: 'neutrals40',
      }}
      alignItems="center"
      analyticsName={`cosmos-exclusives-home-search-result`}
      aria-label={`Go to ${label}`}
      as="a"
      // @ts-expect-error - This is a known deviation from the design system.
      borderRadius={12}
      color="neutrals100"
      flexShrink={0}
      gap={3}
      height={56}
      href={href}
      id={id}
      justifyContent="flex-start"
      onClick={onClick}
      outline="none"
      px={5}
      py={0}
      rel="noopener"
      target="_blank"
      /**
       * Since we're using the `as` prop to convert this to an `a` element, we
       * need to set `type` to undefined to prevent it from being passed to
       * the DOM.
       */
      type={undefined}
      variant="ghost"
    >
      <Icon color="inherit" css={{ width: 20, height: 20 }} name="search" />
      <Text as="span" color="inherit" fontSize="s1" lineHeight="s1">
        {searchQueryRegExps?.length
          ? words.map((word, wordIndex) => {
              const regex = searchQueryRegExps.find((r) => r.test(word));
              const parts = regex ? word.split(regex) : [word];

              return parts.map((part, partIndex) =>
                regex && regex.test(part) ? (
                  <Text
                    as="span"
                    fontWeight="medium"
                    key={`word-${wordIndex}-part-${partIndex}`}
                    textAlign="left"
                  >
                    {part}
                  </Text>
                ) : (
                  <Text
                    as="span"
                    key={`word-${wordIndex}-part-${partIndex}`}
                    textAlign="left"
                    whiteSpace="pre"
                  >
                    {part}
                  </Text>
                ),
              );
            })
          : label}
      </Text>
    </Button>
  );
};

const SPACE_REGEX = new RegExp('( )', 'gi');
