/* storybook-check-ignore */
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { createPortal } from 'react-dom';

import { Flex } from '@opendoor/bricks/core';
import { motion } from 'framer-motion';

import { HEADER_HEIGHT } from 'components/exclusives/layout/Headerv2/Headerv2';

export type MobileHeaderSearchResultsProps = ComponentPropsWithoutRef<typeof MotionFlex>;

export const MobileHeaderSearchResults = forwardRef<HTMLDivElement, MobileHeaderSearchResultsProps>(
  (props, forwardedRef) => {
    /**
     * We insert the portal into the body because the header has
     * an `overflow: hidden;` applied to it on mobile which would
     * cause the search results to be cut off.
     */
    return createPortal(
      <MotionFlex
        animate={{ opacity: 1 }}
        as="nav"
        exit={{ opacity: 0 }}
        flexDirection="column"
        height="var(--doc-height)"
        initial={{ opacity: 0 }}
        left={5}
        maxHeight={`calc(var(--doc-height) - ${HEADER_HEIGHT}px)`}
        position="fixed"
        px={0}
        py={5}
        overflowY="auto"
        ref={forwardedRef}
        right={5}
        top={72}
        transition={{
          duration: 0.2,
        }}
        zIndex={100}
        {...props}
      />,
      document.body,
    );
  },
);

MobileHeaderSearchResults.displayName = 'MobileHeaderSearchResults';

const MotionFlex = motion(Flex);
