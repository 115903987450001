// storybook-check-ignore
import * as React from 'react';

import styled from '@emotion/styled';
import Cookies from 'js-cookie';

import { ADMIN_URL, XSRF_COOKIE_KEY } from '../globals';

const ImpersonationContainer = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #ab80de;
  color: #ffffff;
  padding: 0px 40px;
  width: 100%;
`;

const LinkButton = styled.button`
  color: #ffffff;
  background: none;
  box-shadow: none;
  border: none;
  font-size: 16px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

type ImpersonationProps = {
  email: string;
  name: string;
};

// The impersonation header allows for impersonating users to stop impersonating
// that user. This is on the homepage to reduce changes in process for internal
// operators, who frequently impersonate customers to investigate issues.
// Unimpersonating is built deeply into the workflow.
// This formats a form post to the /admin/impersonation endpoint, with an authenticity
// key (XSRF header) to validate the request. It will only show up for internal
// employees.
const ImpersonationHeader: React.FC<ImpersonationProps> = (props) => {
  return (
    <ImpersonationContainer>
      <span>
        {props.name || 'Missing Name'} ({props.email || 'Missing e-mail'})
      </span>
      <form action={`${ADMIN_URL}/admin/impersonation`} acceptCharset="UTF-8" method="post">
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value="delete" />
        <input type="hidden" name="authenticity_token" value={Cookies.get(XSRF_COOKIE_KEY)} />
        <LinkButton>Stop Impersonating</LinkButton>
      </form>
    </ImpersonationContainer>
  );
};

export default ImpersonationHeader;
