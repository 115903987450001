/* storybook-check-ignore */
import { createContext, ProviderProps, useContext, useEffect, useMemo, useState } from 'react';

import useMedia from '@opendoor/bricks/hooks/useMedia';

/**
 * We use the legacy media breakpoints here because Exclusive's is
 * currently used the legacy breakpoints in the `<ThemeProvider />`.
 *
 * @TODO We should update audit and update Exclusive's theme to the
 * latest version of the breakpoints in the future.
 */
import { BREAKPOINTS_DICT as EERO_BREAKPOINTS_DICT } from '@opendoor/bricks/theme/legacy/foundations/mediaBreakpoints';
import { BREAKPOINTS_DICT as NOVO_BREAKPOINTS_DICT } from '@opendoor/bricks/theme/novo';

export type MediaQueryContextType = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
};

export const MEDIA_QUERY_CONTEXT_DEFAULT_VALUES = {
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  isLargeDesktop: false,
};

export const MediaQueryContext = createContext<MediaQueryContextType>(
  MEDIA_QUERY_CONTEXT_DEFAULT_VALUES,
);

const BREAKPOINTS_DICT = {
  eero: EERO_BREAKPOINTS_DICT,
  novo: NOVO_BREAKPOINTS_DICT,
};

export type MediaQueryContextProviderProps = Pick<
  ProviderProps<MediaQueryContextType>,
  'children'
> & {
  defaultValue?: Partial<MediaQueryContextType>;
  theme?: 'eero' | 'novo';
};

export const MediaQueryContextProvider = ({
  children,
  theme = 'eero',
}: MediaQueryContextProviderProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const isMobile = useMedia(`(max-width: ${BREAKPOINTS_DICT[theme].MD - 1}px)`);
  const isTablet = useMedia(
    `(min-width: ${BREAKPOINTS_DICT[theme].MD}px) and (max-width: ${
      BREAKPOINTS_DICT[theme].LG - 1
    }px)`,
  );
  const isDesktop = useMedia(`(min-width: ${BREAKPOINTS_DICT[theme].LG}px)`);
  const isLargeDesktop = useMedia(`(min-width: ${BREAKPOINTS_DICT[theme].XL}px)`);

  useEffect(() => setIsMounted(true), []);

  const values = useMemo(() => {
    /**
     * Since we're using breakpoints in locations that are server-sided rendered, using
     * just the `useMedia` by itself will cause hydration issues. This is why we're
     * returning the default values if the component hasn't been mounted yet.
     */
    if (!isMounted) return MEDIA_QUERY_CONTEXT_DEFAULT_VALUES;

    return {
      isMobile,
      isTablet,
      isDesktop,
      isLargeDesktop,
    };
  }, [isMounted, isMobile, isTablet, isDesktop, isLargeDesktop]);

  return <MediaQueryContext.Provider value={values}>{children}</MediaQueryContext.Provider>;
};

export const useMediaQueryContext = () => {
  return useContext(MediaQueryContext);
};
