/* storybook-check-ignore */
import { Box, Flex, FlexProps } from '@opendoor/bricks/core';
import { motion } from 'framer-motion';

export type HeaderSearchOverlayProps = Pick<FlexProps, 'onClick'>;

export const HeaderSearchOverlay = ({ onClick }: HeaderSearchOverlayProps) => {
  return (
    <MotionFlex
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      flexDirection="column"
      height="var(--doc-height)"
      initial={{
        opacity: 0,
      }}
      inset={0}
      onClick={onClick}
      position="fixed"
      transition={{
        duration: 0.2,
      }}
      width="100vw"
      zIndex={1}
    >
      <Box backgroundColor="neutrals0" flexShrink={0} height={72} opacity={0.8} />
      <Box backgroundColor="neutrals0" flexGrow={1} />
    </MotionFlex>
  );
};

const MotionFlex = motion(Flex);
