import { Listing } from '../../declarations/exclusives/listing';

export function getMapLink({ address: { human_readable } }: Listing): string | undefined {
  if (!human_readable) return undefined;
  const encodedHumanAddress = encodeURI(human_readable);
  return `https://www.google.com/maps/search/?api=1&query=${encodedHumanAddress}`;
}

const EARTH_RADIUS_IN_MILES = 3963;

function degreesToRadius(deg: number): number {
  return (deg * Math.PI) / 180;
}

// Haversine's formula
export function getDistanceFromLonLat(
  [lon1, lat1]: [number, number],
  [lon2, lat2]: [number, number],
  earthRadiusInUnit: number = EARTH_RADIUS_IN_MILES,
): number {
  const latDif = degreesToRadius(lat1 - lat2);
  const lonDif = degreesToRadius(lon1 - lon2);
  const a =
    Math.sin(latDif / 2) * Math.sin(latDif / 2) +
    Math.cos(degreesToRadius(lat1)) *
      Math.cos(degreesToRadius(lat2)) *
      Math.sin(lonDif / 2) *
      Math.sin(lonDif / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusInUnit * c;
}

export const toFeature = (listing: Listing) => {
  const { lonlat } = listing;

  return {
    type: 'Feature',
    properties: { ...listing },
    geometry: {
      type: 'Point',
      coordinates: lonlat,
    },
  };
};

function getXYZ({ geometry: { coordinates } }: ReturnType<typeof toFeature>) {
  const long = (coordinates[0] * Math.PI) / 180;
  const lat = (coordinates[1] * Math.PI) / 180;
  return {
    x: Math.cos(lat) * Math.cos(long),
    y: Math.cos(lat) * Math.sin(long),
    z: Math.sin(lat),
  };
}

export function getCenter(features: ReturnType<typeof toFeature>[]): [number, number] | undefined {
  if (!features.length) return;

  const { x, y, z, amount } = features.reduce(
    (acc, feature) => {
      if (feature) {
        const { x, y, z } = getXYZ(feature);
        acc.amount += 1;
        acc.x += x;
        acc.y += y;
        acc.z += z;
      }
      return acc;
    },
    { x: 0, y: 0, z: 0, amount: 0 },
  );

  const mediumX = x / amount;
  const mediumY = y / amount;
  const mediumZ = z / amount;
  const centralLong = Math.atan2(mediumY, mediumX);
  const centralSQRT = Math.sqrt(mediumX * mediumX + mediumY * mediumY);
  const centralLati = Math.atan2(mediumZ, centralSQRT);
  return [(centralLong * 180) / Math.PI, (centralLati * 180) / Math.PI];
}
